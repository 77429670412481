import 'core-js/fn/object/assign';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import RouterConfig from './router'
import 'babel-polyfill'
ReactDOM.render(
    <Provider>
        <RouterConfig />
    </Provider>,
document.getElementById('root'));
