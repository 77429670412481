import '../App.less'
import * as React from "react";
import axios from 'axios'
import Slider from '../components/Slider';
import Base64 from 'base-64';
import { getWay } from '../config/url';
let interval: any = null;
interface IndexState {
    url?: string,
    num?: number,
    usr?: any,
    password?: any,
    errorMsg?: string,
    time?: number,
    timerTxt?: string,
    phone?: number,
    smsCode?: number,
    tipMsg?: string,
    token?: string,
    uid?: any,
    pwd?: any,
    loginType?: any,
    from?: string
};
interface IndexProps {
    fileUrl?: any
};
class App extends React.Component<IndexProps, IndexState> {
    constructor(props: IndexProps) {
        super(props);
        this.state = {
            num: 0,
            usr: undefined,
            password: undefined,
            errorMsg: undefined,
            time: 60,
            timerTxt: '获取验证码',
            phone: undefined,
            smsCode: undefined,
            tipMsg: undefined,
            token: undefined,
            uid: undefined,
            pwd: undefined,
            loginType: 7,
            from: undefined,
        }
    }
    public changeLabel(i: number) {
        this.setState({
            num: i,
            tipMsg: ''
        })
    }
    public getInputVal(key: string, e: any) {
        this.setState({
            [key]: e.target.value
        })
    }
    public async componentDidMount() {
        let props: any = this.props;
        let param = props['match']['params']
        let decode = param['param'] ? JSON.parse(Base64.decode(param['param'])) : {}
        this.setState({
            token: decode.token,
            loginType: decode['loginType'] ? decode['loginType'] : 7,
            from: decode['fromUrl'] ? decode['fromUrl'] : ''
        })
    }
    // 账户登录
    public loginByUsr() {
        let { usr, password, from } = this.state;
        if (!usr) {
            this.setState({
                tipMsg: '请输入账号'
            })
            return false;
        }
        if (!password) {
            this.setState({
                tipMsg: '请输入密码'
            })
            return false;
        }
        axios.post(`${getWay}/AUTH/loginByUser?loginName=${usr}&loginPwd=${password}&appId=1058`).then((res) => {
            let resData = res.data
            if (resData.success) {
                if (from) {
                    window.location.href = `${from}?DM_AUTH=${resData.data}`
                }
            } else {
                this.setState({
                    tipMsg: resData.message
                })
            }
        })
    }
    // 手机号登录
    public loginByPhone() {
        let { phone, smsCode, from } = this.state;
        if (!phone) {
            this.setState({
                tipMsg: '请输入手机号'
            })
            return false;
        }
        if (!smsCode) {
            this.setState({
                tipMsg: '请输入验证码'
            })
            return false;
        }
        axios.post(`${getWay}/AUTH/loginByPhone?phone=${phone}&validateNo=${smsCode}&appId=1058&action=2`).then((res) => {
            let resData = res.data
            if (resData.success) {
                if (from) {
                    window.location.href = `${from}?DM_AUTH=${resData.data}}`
                }
            } else {
                this.setState({
                    tipMsg: resData.message
                })
            }
        })
    }
    // ERP账户登录
    public loginByErp() {
        let { uid, pwd, from } = this.state;
        let form = new FormData();
        if (!uid) {
            this.setState({
                tipMsg: '请输入账号'
            })
            return false;
        }
        if (!pwd) {
            this.setState({
                tipMsg: '请输入密码'
            })
            return false;
        }
        form.append('uid', uid);
        form.append('pwd', pwd);
        axios.post(`${getWay}/ERP/login`, form).then((res) => {
            let resData = res.data
            if (resData.success) {
                if (from) {
                    window.location.href = `${from}?DM_AUTH=${JSON.parse(resData.ext5).data}`
                }
            } else {
                this.setState({
                    tipMsg: resData.msg
                })
            }
        })
    }
    tick = () => {
        const { time }: any = this.state;
        let txt: any = `${time - 1} (s)`
        if (time == 0) {
            clearInterval(interval)
            this.setState({
                time: 60,
                timerTxt: '获取验证码'
            })
        } else {
            this.setState({
                timerTxt: txt,
                time: time - 1
            })
        }

    }
    // 发送验证码
    public async sendSms() {
        let { phone, smsCode, tipMsg, token, timerTxt } = this.state;
        let phoneReg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
        if (!phone) {
            this.setState({
                tipMsg: '请输入正确的手机号'
            })
            return false;
        }
        if (!phoneReg.test(`${phone}`)) {
            this.setState({
                tipMsg: '请输入正确的手机号'
            })
            return false;
        }
        let _this = this
        let win: any = window;
        let data = await axios({
            url: `${getWay}/SERVICE-MT-APP/center/sendCode`,
            params: { "appId": 1058, "action": 2, "phone": phone, "signName": '海德假期', "templateCode": 'SMS_86895056', "url": `${win.getNVCVal()}` },
            headers: { "DM_AUTH": token },
            method: 'post'
        })
        if (data.data.code == 100 || data.data.code == 200) {
            if (data.data.code == 200) {
                this.setState({
                    timerTxt: '60(s)'
                })
                interval = setInterval(() => this.tick(), 1000);
            }
        } else if (data.data.code == 400) {
            _this.setState({
                timerTxt: '获取验证码',
                tipMsg: ''
            })
            win.getNC().then(function () {
                win._nvc_nc.upLang('cn', {
                    _startTEXT: "请按住滑块，拖动到最右边",
                    _yesTEXT: "验证通过",
                    _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
                    _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>",
                })
                win._nvc_nc.reset()
            })
        } else if (data.data.code == 600) {
            _this.setState({
                timerTxt: '获取验证码',
                tipMsg: ''
            })
            //唤醒刮刮卡
            win.getSC().then(function () {

            })
        } else if (data.data.code == 900 || data.data.code == 800) {
            _this.setState({
                tipMsg: '发送频繁，请稍后重试',
                timerTxt: '获取验证码'
            })
        } else {
            _this.setState({
                tipMsg: '发送失败，请稍后重试',
                timerTxt: '获取验证码',
            })
        }
    }
    render() {
        let { num, timerTxt, tipMsg, loginType } = this.state;
        let win:any=window;
        let imgs = win.config_data.sliderImgs;
        let loginTypes = ['账号', '手机号', 'ERP'];
        let fShow, sShow, tShow;
        if (loginType == 1) {
            loginTypes = ['账号'];
            fShow = true;
        } else if (loginType == 2) {
            loginTypes = ['手机号'];
            sShow = true
        } else if (loginType == 3) {
            if (num === 0) {
                fShow = true
            } else if (num === 1) {
                sShow = true
            }
            loginTypes = ['账号', '手机号'];
        } else if (loginType == 4) {
            loginTypes = ['ERP'];
            tShow = true
        } else if (loginType == 7) {
            loginTypes = ['账号', '手机号', 'ERP'];
            if (num === 0) {
                fShow = true
            } else if (num === 1) {
                sShow = true
            } else if (num === 2) {
                tShow = true
            }
        } else if (loginType == 5) {
            if (num === 0) {
                fShow = true
            } else if (num === 1) {
                tShow = true
            }
            loginTypes = ['账号', 'ERP'];
        } else if (loginType == 6) {
            if (num === 0) {
                sShow = true
            } else if (num === 1) {
                tShow = true
            }
            loginTypes = ['手机号', 'ERP号'];
        }
        let typesWrap: any[] = [];
        loginTypes.map((type, t) => {
            typesWrap.push(
                <div className={num === t ? 'labelItem activeLabel' : 'labelItem'} key={t} onClick={this.changeLabel.bind(this, t)}>{type}</div>
            )
        })
        return (
            <div>
                <div className='loginWrap'>
                    <Slider imgs={imgs}></Slider>
                    <div className='midline'></div>
                    <div className='loginContent'>
                        <div className='loginBox'>
                            <div className='label'>
                                {typesWrap}
                            </div>
                            {fShow && <div className='accountLogin'>
                                <div className='inputBox'>
                                    <span className='iconfont icon-usr'></span>
                                    <input type="text" onChange={this.getInputVal.bind(this, 'usr')} placeholder='请输入账号' />
                                </div>
                                <div className='inputBox'>
                                    <span className='iconfont icon-password'></span>
                                    <input type="password" onChange={this.getInputVal.bind(this, 'password')} placeholder='请输入密码' />
                                </div>
                                <div className='tipMsg'>{tipMsg && <span className='iconfont icon-gantanhao'></span>}{tipMsg}</div>
                                <div className='loginBtn' onClick={this.loginByUsr.bind(this)}>登录</div>
                            </div>}
                            {sShow && <div className='accountLogin'>
                                <div className='inputBox'>
                                    <span className='iconfont icon-phone'></span>
                                    <input type="text" onChange={this.getInputVal.bind(this, 'phone')} placeholder='请输入手机号' />
                                </div>
                                <div className='inputBox'>
                                    <span className='iconfont icon-verify'></span>
                                    <input type="text" onChange={this.getInputVal.bind(this, 'smsCode')} placeholder='请输入验证码' />
                                    <div className='sendCode' onClick={timerTxt === '获取验证码' ? this.sendSms.bind(this) : undefined}>{timerTxt}</div>
                                </div>
                                <div className='tipMsg'>{tipMsg && <span className='iconfont icon-gantanhao'></span>}{tipMsg}</div>
                                <div className='loginBtn' onClick={this.loginByPhone.bind(this)}>登录</div>
                            </div>}
                            {tShow && <div className='accountLogin'>
                                <div className='inputBox'>
                                    <span className='iconfont icon-usr'></span>
                                    <input type="text" onChange={this.getInputVal.bind(this, 'uid')} placeholder='请输入ERP账号' />
                                </div>
                                <div className='inputBox'>
                                    <span className='iconfont icon-password'></span>
                                    <input type="password" onChange={this.getInputVal.bind(this, 'pwd')} placeholder='请输入密码' />
                                </div>
                                <div className='tipMsg'>{tipMsg && <span className='iconfont icon-gantanhao'></span>}{tipMsg}</div>
                                <div className='loginBtn' onClick={this.loginByErp.bind(this)}>登录</div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default App