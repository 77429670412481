import React from "react";
import MediaQuery from "react-responsive";
import {
  Route,
  BrowerRouter,
  HashRouter as Router,
  hashHistory,
  Link,
  Switch,
  browserHistory
} from "react-router-dom";
import Home from '../pages/App'
import HomeM from '../pages/AppM'
const routes = [
  {
      path:'/:param?/:from?',
      exact: true,
      component: Home
  }
]
const routes1 = [
  {
    path:'/:param?/:from?',
    exact: true,
    component: HomeM
  },
];
export default class Root extends React.Component {
  render() {
    return (
      <div style={{height:'100%'}}>
        <div className='mPage'>
          <MediaQuery query='(max-device-width: 980px)'>
            <Router>
            <Switch>
              {routes1.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              {/* {<Route component={ErrorPage} />} */}
            </Switch>
          </Router>
        </MediaQuery>
        </div>
        <div className='pcPage'>
          <MediaQuery query='(min-device-width: 980px)'>
            <Router>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              {/* {<Route component={ErrorPage} />} */}
            </Switch>
          </Router>
        </MediaQuery>
        </div>
      </div>
      
    );
  }
}
