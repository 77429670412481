import * as React from "react";
import '../App.less'

interface SliderState {
    now: any,
    num: number
};
interface SliderProps {
    imgs?: any[]
};
class Slider extends React.Component<SliderProps, SliderState>{
    constructor(props: SliderProps) {
        super(props);
        this.state = {
            now: undefined,
            num: 0
        }
    }
    componentDidMount() {
        // 轮播图
        let _this = this
        var wrap: any = document.querySelector(".wrap");
        var box: any = document.querySelector(".box");
        box.innerHTML += box.innerHTML;
        var aLi: any = document.querySelectorAll(".box li");
        var aNav: any = document.querySelectorAll(".nav span");
        if(aNav.length===1){
            return false;
        }
        var aWidth = wrap.offsetWidth;
        box['style'].width = aLi.length * 100 + "%";
        for (var i = 0; i < aLi.length; i++) {
            aLi[i]['style'].width = 1 / aLi.length * 100 + "%";
        }
        var now = 0;
        var startPoint = 0;
        var startEle = 0;
        var timer: any = null;
        for (let i = 0; i < aNav.length; i++) {
            aNav[i]['onclick'] = function () {
                now = i;
                tab(); auto();
            }
        }
        this.cssTransform(box, "translateX", 0);
        start();
        auto();
        wrap.addEventListener("touchstart", function (e: any) {
            clearInterval(timer);
            box.style.transition = "none";
            var moveX = _this.cssTransform(box, "translateX");
            now = Math.round(-moveX / aWidth);
            if (now == 0) {
                now = aNav.length;
            } else if (now == aLi.length - 1) {
                now = aNav.length - 1;
            }
            _this.cssTransform(box, "translateX", -now * aWidth);
            startPoint = e.changedTouches[0].pageX;
            startEle = _this.cssTransform(box, "translateX");
            _this.setState({
                now: now % aNav.length
            })
        });
        wrap.addEventListener("touchmove", function (e: any) {
            var endPoint = e.changedTouches[0].pageX;
            var disX = endPoint - startPoint;
            _this.cssTransform(box, "translateX", disX + startEle);
        });
        wrap.addEventListener("touchend", function (e: any) {
            var moveX = _this.cssTransform(box, "translateX");
            now = Math.round(-moveX / aWidth);
            tab(); auto();
            _this.setState({
                now: now % aNav.length
            })

        });
        function auto() {
            clearInterval(timer);
            timer = setInterval(function () {
                if (now == aLi.length - 1) {
                    now = aNav.length - 1;
                }
                _this.setState({
                    now: now % aNav.length
                })
                box['style'].transition = "none";
                _this.cssTransform(box, "translateX", -now * aWidth);
                setTimeout(function () {
                    now++;
                    tab();
                    _this.setState({
                        now: now % aNav.length
                    })
                }, 30);
            }, 3000);
        };
        function start() {
            if (now == aLi.length - 1) {
                now = aNav.length - 1;
            }
            _this.setState({
                now: now % aNav.length
            })
        };
        function tab() {
            box['style'].transition = ".5s";
            _this.cssTransform(box, "translateX", -now * aWidth);
            for (var i = 0; i < aNav.length; i++) {
                aNav[i]['className'] = "";
            };
            aNav[now % aNav.length]['className'] = "active";
        }
    }
    cssTransform(ele: any, attr: any, val?: any) {
        if (!ele.transform) {
            ele.transform = {};
        };
        //当传入值时对属性进行设置。
        if (arguments.length > 2) {
            ele.transform[attr] = val;
            var sval = "";
            for (var s in ele.transform) {
                if (s == "translateX") {
                    sval += s + "(" + ele.transform[s] + "px)";
                }
                ele.style.WebkitTransform = ele.style.transform = sval;
            }

        } else {
            val = ele.transform[attr];
            if (typeof val == "undefined") {
                if (attr == "translateX") {
                    val = 0;
                }
            };
            return val
        }
    }
    public render() {
        let { imgs }: any = this.props;
        let lis: any[] = [], spans: any[] = [];
        imgs.map((img: any, i: number) => {
            lis.push(
                <li key={i}><a><img src={img} /></a></li>
            )
            spans.push(
                <span key={i}></span>
            )
        })
        return (
            <div className="wrap sliderWrapper">
                <ul className="box">
                    {lis}
                </ul>
                <div id="nav" className='nav' style={{visibility:spans.length===1?'hidden':'visible'}}>
                    {spans}
                </div>
            </div>
        )
    }
}
export default Slider;
